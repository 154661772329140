<template>
    <div>
      <div class="mx-auto px-6 sm:px-6 py-4 mt-4">
        <div class="py-4">
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>
              <h2 class="text-2xl font-semibold leading-tight">{{titlePage}}</h2>
            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'MachinesGroups',params: {pageno:pageno,perpage: perPage,order:currentSortRoute,search:search} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
          <div class="shadow-md">
            <form @submit.prevent="validateBeforeSubmit" class="form" id="frm">
              <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-gray-400 rounded-t"></div>
              <div class="bg-white space-y-6">
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="code">
                        Name *
                      </label>
                      <input name="name" v-model="label" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('name') }" id="name" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('name')">{{errors.first('name')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>

            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'MachinesGroups',params: {pageno:pageno,perpage: perPage,order:currentSortRoute,search:search} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import qs from 'qs';
import { AUTH_URL } from "actions/auth";
import { Validator } from 'vee-validate';

const dict = {
  custom: {
    owner: {
      required: 'Your owner is empty'
    },
    machine_sn: {
      required: () => 'Your serial number is empty'
    },
    uic: {
      required: 'Your company uic is empty'
    },
    name: {
      required: 'Your name is empty'
    }
  }
};

Validator.localize('en', dict);

const methods = {
  validateBeforeSubmit(e){
    this.$router.push({name: 'MachinesGroups',params: {pageno:this.pageno,perpage: this.perPage,order:this.currentSortRoute,search:this.search} })
    /*this.$validator.validateAll().then((result) => {
      if (result) {
          let myForm = document.getElementById('frm');
          let formData = new FormData(myForm);
          const data = {};
          for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
          }

          if(this.$route.params.id!='' && this.$route.params.id!=undefined){
            var metodo = 'PUT';
            Object.assign(data, {id: this.$route.params.id})
          }else{
            var metodo = 'POST';
          }
          axios({url: AUTH_URL+'master/api/v2/company', data: qs.stringify(data), method: metodo ,
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem("user-token"),
              'Accept' : '*\/*',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
              }
            })
            .then(resp => {
              //console.log(resp.data)
            })
            .catch(err => {
              this.error = true;
              console.error(err)
          })
          this.$router.push("/machines");
        return;
      }
    });*/
  }
};

export default {
  name: 'machinesTypeDetail',
  data() {
    return {
      titlePage: 'New Machine Group',
      currentId: 0,
      detail: [],
      owner: '',
      label: '',
      owners: [],
      machinetype: [],
      alarms: [],
      serials:[],
      pageno: this.$route.params.pageno,
      perPage: this.$route.params.perpage,
      currentSortRoute: this.$route.params.order,
      search : this.$route.params.search,
    };
  },
  components:{
    Validator
  },
  methods,
  mounted: function mounted(){

  },
  beforeMount: function beforeMount(){
    if(this.$route.params.id!=undefined){
      console.log(this.$route.params)
      this.titlePage = 'Edit Machine Group';
      this.currentId = this.$route.params.id;
      this.detail = this.$route.params.dati;
      this.owner = this.$route.params.dati.owner;
      this.label = this.$route.params.dati.label;
    }
    axios({url: AUTH_URL+'master/api/v2/machine_groups', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.owners = resp.data
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
    axios({url: AUTH_URL+'master/api/v2/machinetype', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.machinetype = resp.data
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
    axios({url: AUTH_URL+'master/api/v2/alarmsdb', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.alarms = resp.data
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
    axios({url: AUTH_URL+'master/api/v2/serials', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.serials = resp.data
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
  }
}
</script>
